<template>
  <div class="list-products">
    <KTCodePreview v-bind:title="'Danh sách sản phẩm'">
      <template v-slot:toolbar>
        <b-row>
          <b-dropdown
            size="sm"
            right
          >
            <template slot="button-content">
              <i
                style="font-size: 1rem"
                class="fas fa-cog"
              ></i>
              <span class="font-weight-bolder">Thao tác</span>
            </template>
            <b-dropdown-item @click="excelModal">
              <span>
                <i
                  style="font-size: 1rem"
                  class="far fa-file-excel"
                ></i>
                &nbsp; Xuất Excel</span>
            </b-dropdown-item>
            <b-dropdown-item @click="onClickToPrint">
              <span>
                <i
                  style="font-size: 1rem"
                  class="fas fa-print"
                ></i>
                &nbsp; In sản phẩm</span>
            </b-dropdown-item>
          </b-dropdown>
          <b-dropdown
            size="sm"
            right
            split
            variant="primary"
            @click="routeProduct('addProduct')"
            class="ml-4"
          >
            <template v-slot:button-content>
              <div class="font-weight-bolder">
                <span>
                  <i
                    style="font-size: 1rem"
                    class="flaticon2-add-1"
                  ></i>Tạo
                  mới</span>
              </div>
            </template>
            <b-dropdown-item @click="routeProduct('import-product')">
              <i
                style="font-size: 1rem"
                class="far fa-file-excel mr-2"
              ></i>
              <span>Nhập từ file excel</span>
            </b-dropdown-item>

            <!-- @TODO: Hidden temporary -->
            <!-- <b-dropdown-item @click="routeProduct('import-product-combo')">
              <i
                style="font-size: 1rem"
                class="far fa-file-excel mr-2"
              ></i>
              <span>Nhập combo từ file excel</span>
            </b-dropdown-item> -->
          </b-dropdown>
        </b-row>
      </template>
      <template v-slot:preview>
        <b-row class="mb-5">
          <div class="col">
            <b-input
              size="sm"
              placeholder="Tìm kiếm"
              v-model="search"
              append-icon="search"
              single-line
              hide-details
              v-on:keyup.enter="onFilter()"
            ></b-input>
          </div>
          <div class="col">
            <Autosuggest
              :model="selectedCate"
              :suggestions="filteredCateOptions"
              placeholder="danh mục sản phẩm"
              @select="onSelectedCate"
              @change="onInputCateChange"
              suggestionName="suggestionName"
            />
          </div>
          <!-- <div class="col">
            <Autosuggest
              :model="selectedInternalCate"
              :suggestions="filteredInternalCateOptions"
              placeholder="danh mục nội bộ"
              @select="onSelectedInternalCate"
              @change="onInputInternalCateChange"
              suggestionName="suggestionName"
            />
          </div> -->
          <div class="col">
            <Autosuggest
              :model="selectedBrandName"
              :suggestions="filteredBrandOptions"
              placeholder="thương hiệu"
              @select="onSelectedBrand"
              @change="onInputBrandChange"
            />
          </div>
          <div class="col">
            <Autosuggest
              :model="searchStock"
              :suggestions="storeFilteredOptions"
              placeholder="kho để kiểm tra tồn"
              @select="onSelected"
              @change="onInputChangeStore"
            />
          </div>
        </b-row>
        <b-row class="mb-5">
          <div class="col">
            <b-form-select
              size="sm"
              required
              class="select-style"
              v-model="selectedStatus"
              :options="listStatus"
              value-field="id"
              text-field="name"
              disabled-field="notEnabled"
            ></b-form-select>
          </div>
          <div class="col">
            <b-form-select
              size="sm"
              required
              class="select-style"
              v-model="selectedProductType"
              :options="listProductType"
              value-field="id"
              text-field="name"
              disabled-field="notEnabled"
            />
          </div>
          <!-- <b-col>
            <b-form-select
              size="sm"
              required
              class="select-style"
              v-model="selectedVAT"
              :options="[
                { id: null, name: 'Chọn sản phẩm VAT' },
                { id: 'ne', name: 'Có tên VAT' },
                { id: 'eq', name: 'Chưa có tên VAT' },
              ]"
              value-field="id"
              text-field="name"
              disabled-field="notEnabled"
            />
          </b-col> -->
          <!-- <b-col>
            <b-form-select
              class="select-style"
              size="sm"
              v-model="selectedVATRate"
              :options="[
                {
                  id: null,
                  name: 'Chọn thuế suất',
                },
                {
                  id: -1,
                  name: 'KCT',
                },
                {
                  id: 8,
                  name: '8%',
                },
                {
                  id: 10,
                  name: '10%',
                },
              ]"
              value-field="id"
              text-field="name"
            >
            </b-form-select>
          </b-col> -->
          <b-col>
            <Autosuggest
              :model="selectedParent"
              :suggestions="filteredParentProductOptions"
              placeholder="sản phẩm cha"
              :limit="10"
              @select="parentProductOnSelected"
              @change="onSearchParentProductChange"
              suggestionName="suggestionName"
            />
          </b-col>
          <b-col>
            <b-button
              variant="primary"
              size="sm"
              class="w-100 font-weight-bold"
              @click="onFilter"
            >Lọc</b-button>
          </b-col>
        </b-row>
        <b-row class="mb-5">
          <!-- <b-col md="1">
            <b-button
              style="fontweight: 600; width: 70px"
              variant="primary"
              size="sm"
              @click="onFilter"
            >Lọc</b-button>
          </b-col> -->
          <b-col>
            <div class="d-flex justify-content-end">
              <p
                class="mt-3 text-dark"
                style="font-weight: 500; font-size: 13px"
              >
                {{ fromNo }} - {{ toNo }} /
                {{ convertPrice(totalItem) }}
              </p>
            </div>
          </b-col>
        </b-row>
        <b-table
          :items="listProducts"
          :fields="fields"
          class="table-bordered table-hover col-md-12"
          no-local-sorting
          :small="true"
          :busy="onLoading"
        >
          <template v-slot:table-busy>
            <vcl-table
              :speed="5"
              :animate="true"
              :columns="10"
            ></vcl-table>
          </template>
          <!-- header custom -->
          <template v-slot:head(selected)>
            <span>
              <b-form-checkbox
                v-model="checkAllPro"
                size="lg"
                @change="checkAll"
              ></b-form-checkbox>
            </span>
          </template>
          <!-- check bõ -->
          <template v-slot:cell(selected)="row">
            <div class="d-flex justify-content-center">
              <b-form-checkbox
                size="lg"
                v-model="row.item.selected"
                @change="clickSecondIndex"
              ></b-form-checkbox>
            </div>
          </template>
          <template v-slot:cell(id)="row">
            <i
              class="fas fa-copy"
              style="cursor: pointer; font-size: 11px"
              v-clipboard:copy="row.item.id"
              title="Copy mã SP"
              @click="makeToastSuccess('Copy mã SP')"
            ></i>
          </template>
          <template v-slot:cell(count)="row">
            <div
              style="cursor: pointer"
              @click="findParentOrChild(row.item)"
              class="d-flex justify-content-center align-items-center"
            >
              <v-icon
                small
                style="font-size: 13px"
                v-b-tooltip
                :title="checkTooltip(row.item)"
              >{{ checkTypeOfProduct(row.item) }}</v-icon>
            </div>
          </template>
          <!-- ma vach -->
          <template v-slot:cell(barCode)="row">
            <div v-if="row.item.barCode !== ''">
              <b v-text="row.item.barCode"></b>
              <p
                class="productCode mt-1"
                @click="editItem(row.item)"
              >
                <span
                  v-text="
                   maxCharDisplay(row.item.productCode,15)
                  "
                  style="cursor: pointer"
                ></span>
              </p>
            </div>
            <div
              v-else
              style="cursor: pointer"
              class="text-center"
            >
              <v-icon
                small
                style="font-size: 13px"
                v-b-tooltip
                title="Tạo mã vạch"
                @click="showGenBarcodeAlert(row.item)"
              >menu-icon flaticon2-add</v-icon>
            </div>
          </template>
          <!-- ten sp -->
          <template v-slot:cell(productName)="row">
            <div
              @click="editItem(row.item)"
              style="cursor: pointer"
            >
              <span
                v-text="row.item.productName"
                class="d-block"
              ></span>
              <small v-if="row.item.productNameVat && row.item.productNameVat">
                <b class="text-warning">Tên VAT: </b><b>{{ row.item.productNameVat }}</b>
              </small>
            </div>
          </template>
          <template v-slot:cell(VAT)="row">
            <div class="text-center">
              <b v-if="row.item.VAT === VAT_AMOUNT.PERCENT_KCT"> KCT </b>
              <b v-else-if="row.item.VAT"> {{ row.item.VAT }}%</b>
            </div>
          </template>
          <!-- trang thai -->
          <template v-slot:cell(status)="row">
            <span
              v-text="checkStatus(row.item.status)"
              class="label font-weight-bold label-lg label-inline"
              v-bind:class="{
                'label-light-warning': row.item.status === 1,
                'label-light-success': row.item.status === 2,
                'label-light-danger': row.item.status === 3,
                'label-light-default': row.item.status === 4,
              }"
              style="width: max-content"
            ></span>
          </template>
          <template v-slot:cell(originalPrice)="row">
            <div style="text-align: end">
              <span v-text="convertPrice(row.item.originalPrice)"></span>
            </div>
          </template>
          <template v-slot:cell(sellingPrice)="row">
            <div
              @mouseover="hoverPrice = true"
              @mouseleave="hoverPrice = false"
              style="text-align: end; cursor: pointer"
              v-if="checkPermission('PRODUCT_ORIGINAL_PRICE_VIEW')"
            >
              <span @click="handleShowPriceModel(row.item)">
                <i
                  v-if="hoverPrice"
                  class="fas fa-edit"
                ></i>
              </span>

              <span v-text="convertPrice(row.item.sellingPrice)"></span>
            </div>
            <span
              v-text="convertPrice(row.item.sellingPrice)"
              v-else
            ></span>
          </template>
          <template v-slot:cell(totalQuantityInStock)="row">
            <div
              class="productCode"
              style="text-align: center; cursor: pointer"
            >
              <a
                class="font-weight-bold"
                v-bind:href="getLinkDoc(row.item)"
                target="_blank"
              >
                <span v-text="row.item.totalQuantityInStock"></span>
              </a>
            </div>
          </template>

          <template v-slot:cell(totalQuantityInTransfer)="row">
            <div style="text-align: center">
              <span
                class="text-warning"
                v-text="row.item.totalQuantityInTransfer"
              ></span>
            </div>
          </template>
          <template v-slot:cell(actions)="row">
            <div class="d-flex justify-content-center">
              <b-dropdown
                size="sm"
                id="dropdown-left"
                no-caret
                right
              >
                <template slot="button-content">
                  <i
                    style="font-size: 1rem; padding-right: 0px"
                    class="flaticon2-settings"
                  ></i>
                </template>
                <b-dropdown-item @click="handleViewInventoryDetail(row.item)">
                  <span style="color: #3f4254; font-size: 12px">
                    <i
                      style="font-size: 10px"
                      class="flaticon2-box-1"
                    ></i>
                    &nbsp; Chi tiết tồn kho
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="editItem(row.item)"
                  v-if="checkViewOnly()"
                >
                  <span style="color: #3f4254; font-size: 12px">
                    <i
                      style="font-size: 1rem"
                      class="flaticon-eye"
                    ></i>
                    &nbsp; Chi tiết sản phẩm
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="editItem(row.item)"
                  v-if="!checkViewOnly()"
                >
                  <span style="color: #3f4254; font-size: 12px">
                    <i
                      style="font-size: 1rem"
                      class="flaticon2-pen"
                    ></i>
                    &nbsp; Chỉnh sửa
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="showDeleteAlert(row.item)"
                  v-if="checkPermission('PRODUCT_DELETE')"
                >
                  <span style="color: #3f4254; font-size: 12px">
                    <i
                      style="font-size: 1rem; color: #d33"
                      class="flaticon2-rubbish-bin-delete-button"
                    ></i>
                    &nbsp; Xóa
                  </span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </template>
        </b-table>

        <template>
          <b-modal
            v-model="showEditPriceModal"
            hide-footer
            :title="productName"
          >
            <b-row
              class="mb-5"
              v-if="checkPermission('PRODUCT_ORIGINAL_PRICE_VIEW')"
            >
              <b-col>
                <label
                  class="labelInput"
                  for="inputOriginalPrice"
                >Giá nhập</label>
                <b-form-input
                  v-model="originalPrice"
                  size="sm"
                  placeholder="Nhập giá nhập"
                  v-mask="mask"
                  disabled
                ></b-form-input>
              </b-col>
            </b-row>
            <b-row class="mb-5">
              <b-col>
                <label
                  class="labelInput"
                  for="inputSellingPrice"
                >Giá bán</label>
                <b-form-input
                  v-model="sellingPrice"
                  size="sm"
                  placeholder="Nhập giá bán"
                  v-mask="mask"
                ></b-form-input>
              </b-col>
            </b-row>
            <b-row
              v-show="selectedProduct.parentProductId === null"
              class="mb-5"
            >
              <b-col>
                <b-form-checkbox v-model="checkUpdate">Cập nhật giá sản phẩm con theo cha</b-form-checkbox>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-button
                  style="fontweight: 600; width: 70px"
                  variant="primary"
                  size="sm"
                  @click="updateProduct"
                >Lưu</b-button>
                <b-button
                  style="margin-left: 10px; font-weight: 600; width: 70px"
                  variant="secondary"
                  size="sm"
                  @click="handleShowPriceModel"
                >Hủy</b-button>
              </b-col>
            </b-row>
          </b-modal>
        </template>

        <b-row>
          <b-col>
            <p
              class="mt-3 text-dark"
              style="font-weight: 500"
            >
              Tổng số:
              {{ convertPrice(totalItem) }}
            </p>
          </b-col>
          <b-col>
            <b-pagination-nav
              class="customPagination"
              v-if="numberOfPage >= 2"
              :link-gen="linkGen"
              :number-of-pages="numberOfPage"
              use-router
              @change="fetchData"
              align="right"
              first-class="page-item-first btn btn-icon btn-sm m-1"
              prev-class="page-item-prev btn btn-icon btn-sm m-1"
              next-class="page-item-next btn btn-icon btn-sm m-1"
              last-class="page-item-last btn btn-icon btn-sm m-1"
              page-class="btn btn-icon btn-sm border-0 m-1"
            >
              <template v-slot:first-text>
                <span>
                  <i class="ki ki-double-arrow-back icon-xs"></i>
                </span>
              </template>

              <template v-slot:prev-text>
                <i class="ki ki-arrow-back icon-xs"></i>
              </template>

              <template v-slot:next-text>
                <i class="ki ki-arrow-next icon-xs"></i>
              </template>

              <template v-slot:last-text>
                <span class="text-info">
                  <i class="ki ki-double-arrow-next icon-xs"></i>
                </span>
              </template>
            </b-pagination-nav>
          </b-col>
        </b-row>
      </template>
    </KTCodePreview>
    <b-modal
      ref="excel-modal"
      hide-footer
      title="Xuất excel"
      id="excel-modal"
    >
      <v-form
        ref="form"
        lazy-validation
      >
        <div class="mb-4">
          <div class="row">
            <div class="col-6">
              <b-form-group label="Chọn cột cần xuất:">
                <b-form-radio-group
                  id="radio-group-excel-1"
                  v-model="selectedRowExcel"
                  name="radio-sub-component-1"
                  @change.native="showHeader"
                >
                  <b-form-radio value="all">Tất cả</b-form-radio>
                  <!-- <b-form-radio value="row">Chọn cột</b-form-radio> -->
                </b-form-radio-group>
              </b-form-group>
              <b-form-group label="Danh sách cần xuất:">
                <b-form-radio-group
                  id="radio-group-excel-2"
                  v-model="selectedListExcel"
                  name="radio-sub-component-2"
                >
                  <b-form-radio value="all">Tất cả các trang</b-form-radio>
                  <!-- <b-form-radio value="all">Tất cả các trang</b-form-radio> -->
                </b-form-radio-group>
              </b-form-group>
            </div>
            <div
              class="col-6"
              v-show="showHeaderExcel"
            >
              <b-form-group>
                <template #label>
                  Cột:<br />
                  <b-form-checkbox
                    v-model="allSelected"
                    :indeterminate="indeterminate"
                    aria-describedby="products"
                    aria-controls="products"
                    @change="toggleAll"
                  >
                    {{ allSelected ? 'Bỏ chọn tất cả' : 'Tất cả' }}
                  </b-form-checkbox>
                </template>
                <b-form-checkbox-group
                  id="products"
                  v-model="selectedHeader"
                  :options="optionsHeader"
                  name="products"
                  stacked
                ></b-form-checkbox-group>
              </b-form-group>
            </div>
          </div>
        </div>

        <b-button
          style="width: 110px"
          variant="primary"
          size="sm"
          @click="reportClick"
          class="mr-3"
        >
          <v-icon
            small
            class="text-white"
            v-b-tooltip
          >mdi-download</v-icon>
          <strong>Xuất Excel</strong>
        </b-button>

        <b-button
          style="width: 100px"
          variant="secondary"
          size="sm"
          @click="$bvModal.hide('excel-modal')"
        >
          <strong>Hủy</strong>
        </b-button>
      </v-form>
    </b-modal>
  </div>
</template>

<style lang="scss">
.list-products {
  .labelInput {
    font-weight: 600;
  }
  .productCode:hover {
    text-decoration: underline;
  }

  .inputText {
    width: 22%;
  }
  .btn.btn-icon.btn-sm {
    height: calc(1.35em + 1.1rem + 2px);
    width: auto;
  }

  .icon:hover {
    background-color: #90c6fc;
  }

  .dropdown-header {
    color: #3699ff !important;
  }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: middle;
    border-top: 1px solid #ecf0f3;
  }
  .table.b-table.table-sm
    > thead
    > tr
    > [aria-sort]:not(.b-table-sort-icon-left),
  .table.b-table.table-sm
    > tfoot
    > tr
    > [aria-sort]:not(.b-table-sort-icon-left) {
    background-position: right calc(1.5rem / 2) center;
    padding-right: calc(0.3rem + 0.65em);
  }
  .page-item.disabled {
    cursor: not-allowed;
    &.bv-d-xs-down-none {
      .page-link {
        margin: 0.35rem 0.25rem;
        text-align: center;
      }
    }
  }
}
</style>
<style scoped>
input.form-control {
  border: 1px solid #ced4da;
}
.selectedProClass {
  width: 4%;
  text-align: center;
}
.sttProClass {
  width: 20px;
}
.barCodeProClass {
  width: 8%;
}
.codeProClass {
  width: 15%;
  margin-left: 8px;
}
.nameProClass {
  width: 30%;
  padding-left: 8px;
  align-items: center;
}
.nameNotPriceProClass {
  width: 30%;
  padding-left: 8px;
  align-items: center;
}
.importPriceProClass {
  width: 12%;
}
.salePriceProClass {
  width: 12%;
}
.stockProClass {
  width: 4%;
}
.statusProClass {
  width: 12%;
}
.actionProClass {
  width: 7%;
}
</style>
<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import Swal from 'sweetalert2';
import ApiService from '@/core/services/api.service';
import JwtService from '@/core/services/jwt.service';
import localData from '@/utils/saveDataToLocal';
import { VclTable } from 'vue-content-loading';
import { BASE_URL, PRODUCT_IMEI } from '@/utils/constants';
import {
  currencyMask,
  unMaskPrice,
  removeAccents,
  convertPrice,
} from '@/utils/common';
import { createFile, getFileName } from '@/utils/file-download';
import axios from 'axios';
import decounce from 'debounce';
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';
import { getToastInstance } from '@/utils/toastHelper';
import { VAT_AMOUNT } from '@/utils/enum';
import { TIME_TRIGGER } from '@/utils/constants';

export default {
  data() {
    return {
      VAT_AMOUNT,
      checkUpdate: false,
      selectedProduct: '',
      mask: currencyMask,
      originalPrice: '',
      sellingPrice: '',
      productName: '',
      productId: '',
      showEditPriceModal: false,
      hoverPrice: false,
      parentProductId: '',
      childProductId: '',
      selectedProductType: '',
      listProductType: [
        { id: '', name: 'Thể loại' },

        // Tạm thời ẩn option 1, 3 trên UI (cập nhật hoặc xoá sau)
        // { id: 1, name: 'Sản phẩm' },
        { id: 2, name: 'Sản phẩm theo IMEI' },
        // { id: 3, name: 'Sản phẩm Combo' },
        { id: 4, name: 'Sản phẩm dịch vụ' },
        { id: 7, name: 'Sản phẩm linh kiện' },
      ],
      btnCreate: {
        fontWeight: '600!important',
      },
      selected: [],
      checkAllPro: false,
      fields: [
        {
          key: 'selected',
          label: '',
          thStyle: { textAlign: 'center' },
          tdClass: 'selectedProClass',
          thClass: 'selectedProClass',
        },
        {
          key: 'id',
          label: 'ID',
          sortable: false,
          thStyle: { textAlign: 'center', color: 'rgb(24, 28, 50)' },
          tdClass: 'sttProClass',
          thClass: 'sttProClass',
        },
        {
          key: 'count',
          label: 'Loại',
          sortable: false,
          thStyle: { textAlign: 'center', color: 'rgb(24, 28, 50)' },
          tdClass: 'sttProClass',
          thClass: 'sttProClass',
        },
        {
          key: 'barCode',
          label: 'Mã vạch',
          sortable: false,
          thStyle: { textAlign: 'center', color: 'rgb(24, 28, 50)' },
          tdClass: 'barCodeProClass',
          thClass: 'barCodeProClass',
        },
        {
          key: 'productName',
          label: 'Tên sản phẩm',
          sortable: false,
          thStyle: { textAlign: 'center', color: 'rgb(24, 28, 50)' },
          tdClass: 'nameProClass',
          thClass: 'nameProClass',
        },
        {
          key: 'VAT',
          label: 'Thuế xuất',
          sortable: false,
          thStyle: { textAlign: 'center', color: 'rgb(24, 28, 50)' },
          tdClass: 'nameProClass',
          thClass: 'nameProClass',
        },
        {
          key: 'originalPrice',
          label: 'Giá nhập',
          sortable: false,
          thStyle: { textAlign: 'center', color: 'rgb(24, 28, 50)' },
          tdClass: 'importPriceProClass',
          thClass: 'importPriceProClass',
        },
        {
          key: 'sellingPrice',
          label: 'Giá bán',
          sortable: false,
          thStyle: { textAlign: 'center', color: 'rgb(24, 28, 50)' },
          tdClass: 'salePriceProClass',
          thClass: 'salePriceProClass',
        },
        {
          key: 'totalQuantityInStock',
          label: 'Tồn trong kho',
          sortable: false,
          thStyle: { textAlign: 'center', color: 'rgb(24, 28, 50)' },
          tdClass: 'stockProClass',
          thClass: 'stockProClass',
        },
        {
          key: 'totalQuantityInTransfer',
          label: 'Đang chuyển kho',
          sortable: false,
          thStyle: { textAlign: 'center', color: 'rgb(24, 28, 50)' },
          tdClass: 'stockProClass',
          thClass: 'stockProClass',
        },
        {
          key: 'status',
          label: 'Trạng thái',
          sortable: false,
          thStyle: { textAlign: 'center', color: 'rgb(24, 28, 50)' },
          tdClass: 'statusProClass',
          thClass: 'statusProClass',
        },
        {
          key: 'actions',
          label: '',
          thStyle: { textAlign: 'center', color: 'rgb(24, 28, 50)' },
          tdClass: 'actionProClass',
          thClass: 'actionProClass',
        },
      ],
      search: '',
      listProducts: [],
      listCategories: [],
      selectedCategory: null,
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      page: 1,
      totalItem: 0,
      selectedStatus: '',
      listStatus: [
        { id: '', name: 'Trạng thái sản phẩm' },
        { id: 1, name: 'Mới' },
        { id: 2, name: 'Đang bán' },
        { id: 3, name: 'Ngừng bán' },
        { id: 4, name: 'Hết hàng' },
      ],
      onLoading: false,
      selectedCate: '',
      cateOptions: [
        {
          data: [],
        },
      ],
      brandOptions: [
        {
          data: [],
        },
      ],
      filteredBrandOptions: [],
      filteredCateOptions: [],
      optionsStore: [
        {
          data: [],
        },
      ],
      selectedBrandId: null,
      selectedStore: null,
      selectedBrandName: '',
      searchStock: '',
      selectedRowExcel: 'all',
      selectedListExcel: 'all',
      showHeaderExcel: false,
      indeterminate: false,
      allSelected: false,
      optionsHeader: [],
      selectedHeader: [],
      optionsHeaderDefault: [],
      barCode: '',
      numberOfPage: 1,
      selectedInternalCate: '',
      selectedInternalCateId: null,
      selectedParent: null,
      filteredParentProductOptions: [],
      filterWithParentProduct: false,
      filteredInternalCateOptions: [],
      internalCateOptions: [
        {
          data: [],
        },
      ],
      fromNo: 0,
      toNo: 0,
      LIMIT_PAGE: 30,
      selectedVAT: null,
      selectedVATRate: null,
      storeFilteredOptions: [],
    };
  },
  components: {
    KTCodePreview,
    VclTable,
    Autosuggest,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Sản phẩm', route: '/products' },
      { title: 'Danh sách sản phẩm' },
    ]);

    if (this.$route.params.updateIDImeiSuccess) {
      this.makeToastSuccess(this.$route.params.message);
      this.$route.params.updateIDImeiSuccess = false;
    }

    if (this.$route.params.importProductSuccess) {
      this.makeToastSuccess(this.$route.params.message);
      this.$route.params.importProductSuccess = false;
    }
  },
  created() {
    this.fetchCategory();
    this.fetchBrand();
    this.fetchData();
    this.checkViewOriginalPrice();
    this.fetchStore();
    this.$nprogress.done();
    this.$nprogress.remove();
    this.fetchInternalCategory();
  },
  watch: {
    selectedHeader(newVal) {
      // Handle changes in individual flavour checkboxes
      if (newVal.length === 0) {
        this.indeterminate = false;
        this.allSelected = false;
      } else if (newVal.length === this.optionsHeader.length) {
        this.indeterminate = false;
        this.allSelected = true;
      } else {
        this.indeterminate = true;
        this.allSelected = false;
      }
    },
  },
  methods: {
    convertPrice,
    ...getToastInstance(),
    maxCharDisplay(str, max = 15) {
      return str.length > max ? str.slice(0, max) + '...' : str;
    },
    updateProduct: async function () {
      const data = {
        id: this.productId,
        originalPrice: this.originalPrice ? unMaskPrice(this.originalPrice) : 0,
        sellingPrice: this.sellingPrice ? unMaskPrice(this.sellingPrice) : 0,
        isUpdateChild: this.checkUpdate,
      };
      await ApiService.put('product/update-price', data).then(
        async (response) => {
          const { status, message } = response.data;
          if (status === 1) {
            this.fetchData();
            this.showEditPriceModal = !this.showEditPriceModal;
            this.makeToastSuccess(message);
          } else {
            this.showEditPriceModal = !this.showEditPriceModal;
            this.makeToastFailure(message);
          }
        },
      );
    },
    handleShowPriceModel(item) {
      this.showEditPriceModal = !this.showEditPriceModal;
      this.selectedProduct = item;
      this.productId = item.id;
      this.productName = item.productName;
      this.originalPrice = item.originalPrice;
      this.sellingPrice = item.sellingPrice;
    },
    findParentOrChild(item) {
      this.selectedCategory = null;
      this.search = '';
      this.selectedStatus = '';
      this.selectedProductType = '';
      if (item.typeOfProduct === 3) {
        this.childProductId = '';
        this.parentProductId = item.parentProductId;
        this.onFilter();
      } else if (item.typeOfProduct === 2) {
        this.parentProductId = '';
        this.childProductId = item.id;
        this.onFilter();
      }
    },
    handleViewInventoryDetail(item) {
      item.productType === PRODUCT_IMEI
        ? this.$router.push({
            name: 'inventory-detail-imei',
            query: { id: item.id },
          })
        : this.$router.push({
            name: 'inventory-detail',
            query: { id: item.id },
          });
    },
    checkTypeOfProduct(item) {
      if (item.typeOfProduct === 1) {
        return 'mdi-star';
      } else if (item.typeOfProduct === 2) {
        return 'fa-sitemap';
      } else if (item.typeOfProduct === 3) {
        return 'fa-clipboard';
      }
    },
    checkTooltip(item) {
      if (item.typeOfProduct === 1) {
        if (item.productType === 3) {
          return 'Sản phẩm combo';
        } else {
          return 'Sản phẩm độc lập';
        }
      } else if (item.typeOfProduct === 2) {
        return 'Danh sách sản phẩm con';
      } else if (item.typeOfProduct === 3) {
        return 'Tới sản phẩm cha';
      }
    },
    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
    editItem: function (item) {
      this.$router.push({
        name: 'update-product',
        query: { id: item.id },
      });
    },
    showDeleteAlert: function (item) {
      Swal.fire({
        title: 'Xóa sản phẩm!',
        text: 'Bạn có chắc muốn xóa sản phẩm này không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        position: 'top',
      }).then((result) => {
        if (result.value) {
          this.deleteItem(item);
        }
      });
    },
    fetchData: function () {
      // reset the check all products checkbox when user change pagination or search products
      if (this.checkAllPro) {
        this.checkAllPro = false;
      }
      this.page = this.$route.query.page;
      if (!this.page) {
        this.page = 1;
      }
      if (this.selectedStore === null) {
        this.selectedStore = null;
      }
      if (this.selectedBrandName.trim() === '') {
        this.selectedBrandId = null;
      }
      if (this.selectedInternalCate.trim() === '') {
        this.selectedInternalCateId = null;
      }
      if (this.selectedCate.trim() === '') {
        this.selectedCategory = null;
      }

      const textSearch = this.search ? this.search.trim() : '';
      if (
        this.selectedCategory !== null ||
        this.selectedInternalCateId !== null ||
        textSearch !== '' ||
        this.selectedStatus !== '' ||
        this.selectedProductType !== ''
      ) {
        this.childProductId = '';
        // this.parentProductId = '';
      }

      this.onLoading = true;

      const param = {
        page: this.page,
        limit: this.LIMIT_PAGE,
        categoryId: this.selectedCategory,
        name: textSearch,
        status: this.selectedStatus,
        productType: this.selectedProductType,
        parentProductId: this.parentProductId,
        childProductId: this.childProductId,
        storeId: this.selectedStore,
        brandId: this.selectedBrandId,
        internalCateId: this.selectedInternalCateId,
        selectedVAT: this.selectedVAT,
        selectedVATRate: this.selectedVATRate,
        filterWithParentProduct: this.filterWithParentProduct,
      };

      const paramQuery = {
        params: param,
      };
      ApiService.query('product', paramQuery)
        .then(({ data }) => {
          this.listProducts = [];
          this.totalItem = data.data.total_row;
          this.fromNo = (this.page - 1) * this.LIMIT_PAGE + 1;
          this.toNo = this.LIMIT_PAGE * this.page;
          data.data.list_product.forEach((item, index) => {
            let product = {
              count:
                this.page >= 2 ? index + 1 + this.page * 10 - 10 : index + 1,
              selected: false,
              ...item,
            };
            this.listProducts.push(product);
          });
          this.numberOfPage = data.data.total_page;
          this.onLoading = false;
        })
        .catch(() => {
          this.onLoading = false;
        });
    },
    fetchBrand: function () {
      this.listBrand = [];
      const paramQuery = {
        params: {
          param: {
            page: 1,
            pageSize: 1000,
            name: '',
          },
        },
      };
      ApiService.query('brands', paramQuery).then((response) => {
        this.brandOptions[0].data = response.data.brands;
        this.filteredBrandOptions = [...this.brandOptions[0].data];
      });
    },
    fetchCategory: function () {
      let param = {
        page: 1,
        limit: 1000,
        name: '',
        code: '',
      };
      let paramQuery = {
        params: param,
      };
      ApiService.query('category', paramQuery).then(({ data }) => {
        this.cateOptions[0].data = [];
        this.numberOfPage = data.data.total_page;
        data.data.list_caterogy.forEach((element) => {
          let name = '';
          if (element.level === 1) {
            name = '-' + element.name;
          } else if (element.level === 2) {
            name = '--' + element.name;
          } else if (element.level === 3) {
            name = '---' + element.name;
          } else if (element.level === 4) {
            name = '----' + element.name;
          } else if (element.level === 5) {
            name = '-----' + element.name;
          } else if (element.level === 6) {
            name = '------' + element.name;
          } else {
            name = element.name;
          }
          let item = {
            id: element.id,
            suggestionName: name,
            name: element.name,
          };
          this.cateOptions[0].data.push(item);
        });

        this.filteredCateOptions = [...this.cateOptions[0].data];
      });
    },
    checkStatus: function (item) {
      switch (item) {
        case 1:
          return 'Mới';
        case 2:
          return 'Đang bán';
        case 3:
          return 'Ngừng bán';
        case 4:
          return 'Hết hàng';
        default:
          return '';
      }
    },
    deleteItem: async function (item) {
      let id = item.id;
      ApiService.setHeader();
      ApiService.delete(`product/${id}`).then(({ data }) => {
        if (data.status === 1) {
          this.makeToastSuccess(data.message);
          this.fetchData();
        } else {
          this.makeToastFailure(data.message);
        }
      });
    },
    checkAll() {
      this.listProducts.forEach((element) => {
        element.selected = true;
        if (!this.checkAllPro) {
          element.selected = false;
        }
      });
    },
    clickSecondIndex() {
      let count = 0;
      this.listProducts.forEach((element) => {
        if (!element.selected) {
          count += 1;
          if (count === 5) {
            this.checkAllPro = false;
          }
        }
      });
    },
    clickIndex() {
      if (this.checkAllPro) {
        this.checkAllPro = false;
      } else {
        var count = 0;
        this.listProducts.forEach((element) => {
          if (element.selected == true) {
            count += 1;
            if (count == 10) {
              this.checkAllPro = true;
            }
          }
        });
      }
    },
    checkViewOnly: function () {
      let count = 0;
      if (localData.checkPermission('PRODUCT_VIEW')) {
        count++;
      }
      if (localData.checkPermission('PRODUCT_UPDATE')) {
        count++;
      }
      if (count === 1) {
        return true;
      } else {
        return false;
      }
    },
    showGenBarcodeAlert: function (item) {
      Swal.fire({
        title: 'Tạo mã vạch!',
        text: 'Bạn có chắc muốn tạo mã vạch cho sản phẩm này không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Tạo',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        position: 'top',
      }).then((result) => {
        if (result.value) {
          this.genBarcode(item);
        }
      });
    },
    genBarcode: async function (item) {
      let id = item.id;
      ApiService.setHeader();
      ApiService.post(`product/gen-barcode/${id}`).then(({ data }) => {
        if (data.status === 1) {
          this.makeToastSuccess(data.message);
          this.fetchData();
        } else {
          this.makeToastFailure(data.message);
        }
      });
    },
    excelModal: async function () {
      this.optionsHeaderDefault = [
        { text: 'Mã vạch', value: 'barCode', disabled: true },
        { text: 'Mã sản phẩm', value: 'productCode', disabled: true },
        { text: 'Tên sản phẩm', value: 'productName', disabled: true },
      ];
      this.selectedHeader = [
        'barCode',
        'productCode',
        'productName',
        'originalPrice',
        'sellingPrice',
        'totalQuantityInStock',
        'totalQuantityInTransfer',
        'status',
      ];
      this.optionsHeader = [
        { text: 'Mã vạch', value: 'barCode', disabled: true },
        { text: 'Mã sản phẩm', value: 'productCode', disabled: true },
        { text: 'Tên sản phẩm', value: 'productName', disabled: true },
        { text: 'Giá nhập', value: 'originalPrice', disabled: false },
        { text: 'Giá bán', value: 'sellingPrice', disabled: false },
        { text: 'Tồn kho', value: 'totalQuantityInStock', disabled: false },
        {
          text: 'Đang chuyển kho',
          value: 'totalQuantityInTransfer',
          disabled: false,
        },
        { text: 'Trạng thái', value: 'status', disabled: false },
      ];
      if (!this.checkPermission('PRODUCT_ORIGINAL_PRICE_VIEW')) {
        this.optionsHeader = this.optionsHeader.filter(
          (item) => item.value !== 'originalPrice',
        );
      }
      this.$refs['excel-modal'].show();
    },
    reportClick: function () {
      const query = [];
      if (this.search && this.search !== '') {
        query.push('name=' + this.search.trim());
      }

      if (this.selectedStatus && this.selectedStatus !== '') {
        query.push(`status=${this.selectedStatus}`);
      }

      if (this.selectedCategory) {
        query.push(`categoryId=${this.selectedCategory}`);
      }

      if (this.selectedInternalCate) {
        query.push(`categoryInternalId=${this.selectedInternalCateId}`);
      }

      if (this.selectedStore && this.selectedStore !== 0) {
        query.push(`storeId=${this.selectedStore}`);
      }

      if (this.selectedBrandName.trim() === '') {
        this.selectedBrandId = null;
      }

      if (this.selectedListExcel !== 'current') {
        if (
          this.selectedCategory !== null ||
          this.search !== '' ||
          this.selectedStatus !== '' ||
          this.selectedProductType !== ''
        ) {
          this.childProductId = '';
          this.parentProductId = '';
        }
        if (this.selectedStore === null) {
          this.selectedStore = 0;
        }
        if (this.selectedBrandId !== null) {
          query.push(`brand=${this.selectedBrandId}`);
        }

        if (this.selectedProductType && this.selectedProductType !== '') {
          query.push(`productType=${this.selectedProductType}`);
        }

        if (this.selectedVAT) {
          query.push(`selectedVAT=${this.selectedVAT}`);
        }

        const queryParams = query.length ? query.join('&') : '';

        const url = `${BASE_URL}product/export-excel?${queryParams}`;

        axios({
          url: url,
          method: 'get',
          responseType: 'blob',
          headers: {
            Authorization: `Bearer ${JwtService.getToken()}`,
          },
        })
          .then((response) => {
            const fileName = getFileName(response);
            createFile(response.data, fileName);
          })
          .catch((err) => {
            console.log('exportExcel error', err);
          });
      }
    },
    debounceInput: decounce(function () {
      this.onFilter();
    }, 500),
    onSelectedCate(option) {
      this.selectedCategory = option.item.id;
      this.selectedCate = option.item.name;
    },
    onSelectedBrand(option) {
      this.selectedBrandId = option.item.id;
      this.selectedBrandName = option.item.name;
    },
    onInputBrandChange(text) {
      this.selectedBrandName = text;

      const filteredData = this.brandOptions[0].data.filter((item) => {
        return (
          removeAccents(item.name)
            .toLowerCase()
            .indexOf(removeAccents(text.toLowerCase())) > -1
        );
      });

      this.filteredBrandOptions = [...filteredData];
    },
    onInputCateChange(text) {
      this.selectedCate = text;

      const filteredData = this.cateOptions[0].data.filter((item) => {
        return (
          removeAccents(item.name)
            .toLowerCase()
            .indexOf(removeAccents(text.toLowerCase())) > -1
        );
      });

      this.filteredCateOptions = [...filteredData];
    },
    checkViewOriginalPrice() {
      if (!this.checkPermission('PRODUCT_ORIGINAL_PRICE_VIEW')) {
        let listNew = this.fields.filter(
          (item) => item.key !== 'originalPrice',
        );
        this.fields = [];
        listNew.forEach((element) => {
          this.fields.push(element);
        });
        for (let index = 0; index < this.fields.length; index++) {
          const element = this.fields[index];
          if (element.key === 'productName') {
            this.fields[index].tdClass = 'nameNotPriceProClass';
            this.fields[index].thClass = 'nameNotPriceProClass';
            break;
          }
        }
      }
    },
    fetchStore: async function () {
      this.optionsStore[0].data = [];
      ApiService.setHeader();
      ApiService.get('/stores/getStores').then((response) => {
        const stores = response.data.data;
        stores.map((element) => {
          this.optionsStore[0].data.push(element);
        });
        this.storeFilteredOptions = [...this.optionsStore[0].data];
      });
    },
    onSelected(option) {
      this.searchStock = option.item.name;
      this.selectedStore = option.item.id;
    },
    onInputChangeStore(text) {
      if (!text) {
        text = '';
      }
      this.searchStock = text;

      /* Full control over filtering. Maybe fetch from API?! Up to you!!! */
      const filteredData = this.optionsStore[0].data.filter((item) => {
        return (
          item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
          item.shortName.toLowerCase().indexOf(text.toLowerCase()) > -1
        );
      });

      this.storeFilteredOptions = [...filteredData];
    },
    onFilter() {
      this.$route.query.page = 1;
      this.$router.push({
        name: 'list-products',
      });
      this.fetchData();
    },
    showHeader() {
      if (this.selectedRowExcel === 'row') {
        this.showHeaderExcel = true;
      } else {
        this.showHeaderExcel = false;
      }
    },
    toggleAll(checked) {
      this.selectedHeader = checked
        ? this.optionsHeader.map((x) => x.value)
        : this.optionsHeaderDefault.map((x) => x.value);
    },
    getLinkDoc(item) {
      let link =
        item.productType === PRODUCT_IMEI
          ? `#/products/inventory-detail-imei?id=${item.id}`
          : `#/products/inventory-detail?id=${item.id}`;
      return link;
    },
    fetchInternalCategory: function () {
      const param = {
        page: 1,
        limit: 1000,
        name: '',
        code: '',
      };
      let paramQuery = {
        params: param,
      };
      ApiService.setHeader();
      ApiService.query('productExternalCategory/get-all', paramQuery).then(
        ({ data }) => {
          this.internalCateOptions[0].data = [];
          data.data.forEach((element) => {
            let name = '';
            if (element.level === 1) {
              name = '- ' + element.name;
            } else if (element.level === 2) {
              name = '- - ' + element.name;
            } else if (element.level === 3) {
              name = '- - - ' + element.name;
            } else if (element.level === 4) {
              name = '- - - - ' + element.name;
            } else if (element.level === 5) {
              name = '- - - - - ' + element.name;
            } else if (element.level === 6) {
              name = '- - - - - - ' + element.name;
            } else {
              name = element.name;
            }
            const item = {
              id: element.id,
              suggestionName: name,
              name: element.name,
            };
            this.internalCateOptions[0].data.push(item);
          });
          this.filteredInternalCateOptions = [
            ...this.internalCateOptions[0].data,
          ];
        },
      );
    },
    onSelectedInternalCate(option) {
      this.selectedInternalCateId = option.item.id;
      this.selectedInternalCate = option.item.name;
    },
    onInputInternalCateChange(text) {
      this.selectedInternalCate = text;

      const filteredData = this.internalCateOptions[0].data.filter((item) => {
        return (
          removeAccents(item.name)
            .toLowerCase()
            .indexOf(removeAccents(text.toLowerCase())) > -1
        );
      });

      this.filteredInternalCateOptions = [...filteredData];
    },
    onClickToPrint() {
      const { href } = this.$router.resolve({
        name: 'print-product',
      });
      window.open(href, '_blank');
    },
    routeProduct(namePath) {
      this.$router.push({
        name: `${namePath}`,
      });
    },
    onSearchParentProductChange(text) {
      this.selectedParent = text;
      this.filterWithParentProduct = false;
      this.parentProductId = null;
      this.debounceInputSearchProduct();
    },
    debounceInputSearchProduct: decounce(function () {
      this.searchProduct(this.selectedParent);
    }, TIME_TRIGGER),
    searchProduct: function (textSearch) {
      const paramQuery = {
        params: {
          name: textSearch,
        },
      };
      ApiService.query('product/searchProductByName', paramQuery).then(
        (response) => {
          const preFilter = [];
          response.data.data.forEach((item) => {
            item.productCode = item.productCode || '';
            let suggestionName = item.productName;
            if (item.productCode) {
              suggestionName = item.productCode + ' - ' + item.productName;
            }
            const product = {
              id: item.id,
              name: item.productName,
              code: item.productCode,
              suggestionName: suggestionName,
            };
            preFilter.push(product);
          });
          this.filteredParentProductOptions = [...preFilter];
        },
      );
    },
    parentProductOnSelected(option) {
      this.parentProductId = option.item.id;
      this.filterWithParentProduct = true;
    },
  },
};
</script>
